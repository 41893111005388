<template>
  <div>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a href="/minhas-plataformas" class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')">Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a :href="'/plataforma/' + $route.params.id_plataforma" class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)">Plataforma {{
                  $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')">Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')">Minhas
                turmas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + fastPrograma.id_programa_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))">Módulo
                {{ fastPrograma.sigla_programa }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#" v-if="fastTipoAvaliacao == 'A'">Avaliação</a>
              <a href="#" v-else-if="fastTipoAvaliacao == 'R'">Recuperação</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content mt-0">
        <div class="container">
          <div class="mt-3 mb-4">
            <a class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + fastPrograma.id_programa_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))">
              <b-icon-arrow-return-left /> Voltar
            </a>
          </div>
          <div class="row">
            <div
              :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-12 ml-0 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 px-0">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
                            <h2 class="aluno_font_color mb-0">
                              {{ fastAtividadeAtual.titulo_atividade }}
                              <span v-if="fastPrograma.carregandoUnidades">...</span>
                              <span v-else>{{ fastTurma.nome_curso }} - {{ fastPrograma.sigla_programa }}</span>
                            </h2>

                            <h3 v-if="fastPrograma.carregandoUnidades" class="aluno_font_color mt-4">
                              Carregando...
                            </h3>
                            <p v-else class="aluno_font_color mt-0">
                              Turma {{ fastTurma.sigla_turma }}
                            </p>

                            <div>
                              <img :src="require('@/assets/images/separador.png')">
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                          <div class="col-12 mt-4">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                                <div
                                  v-if="fastAtividadeAtual.data_previsto_fim && !fastAtividadeAtual.data_fim && fastAtividadeAtual.tempo_atividade && fastAtividadeAtual.tempo_atividade != '00:00:00' && !fastLoadingAvalicao">
                                  <h5>Tempo limite</h5>
                                  <div class="mt-4 d-flex justify-content-center">
                                    <fast-timer :endtime="fastAtividadeAtual.data_previsto_fim" trans='{  
                                      "day":"Dias",
                                      "hours":"Horas",
                                      "minutes":"Minutos",
                                      "seconds":"Segundos",
                                      "expired":"A live irá terminar em:",
                                      "running":"",
                                      "upcoming":"",
                                      "status": {
                                          "expired":"",
                                          "running":"",
                                          "upcoming":""
                                        }}'></fast-timer>
                                  </div>
                                </div>

                                <div v-if="fastLoadingAvalicao"
                                  class="text-center mt-4 mb-4 d-flex justify-content-center">
                                  <h4>Aguarde um momento...</h4>
                                </div>
                                <div
                                  class="mb-4 py-4"
                                  v-else-if="(!fastRequisitosAvaliacao.disponivel && getUrlParameter('tipo') == 'A') || (!fastRequisitosRecuperacao.disponivel && getUrlParameter('tipo') == 'R')">
                                  <a class="btn-novo btn-disable-novo mx-1 py-2 px-4 mb-3 text-light"
                                    @click.prevent="exibeToasty(getUrlParameter('tipo') == 'A' ? 'Avaliação indisponível' : 'Recuperação indisponível', 'error')">
                                    <span><b-icon-play /> {{ getUrlParameter("tipo") == 'A' ? 'Iniciar Avaliação' :
                                      'Iniciar Recuperação' }} </span>
                                  </a>
                                </div>
                                <div v-else class="text-center my-4 d-flex justify-content-center">
                                  <div v-if="!fastAtividadeAtual.data_fim" class="row d-flex justify-content-center">
                                    <a v-if="!fastAtividadeAtual.avaliacoes_pendentes && !fastAtividadeAtual.avaliacao_finalizada"
                                      class="btn-novo btn-primary mx-1 py-2 px-4 text-light"
                                      @click="iniciarAvaliacao('iniciar')">
                                      <span><b-icon-play /> {{ getUrlParameter("tipo") == 'A' ? 'Iniciar Avaliação' :
                                        'Iniciar prova de Recuperação' }} </span>
                                    </a>
                                    <a v-else-if="fastAtividadeAtual.avaliacoes_pendentes"
                                      class="btn-novo btn-primary mx-1 py-2 px-4 mt-2 text-light"
                                      @click="iniciarAvaliacao('continuar')">
                                      <span><b-icon-play /> {{ getUrlParameter("tipo") == 'A' ? 'Continuar Avaliação' :
                                        'Continuar Recuperação' }} </span>
                                    </a>
                                    <a v-else-if="fastAtividadeAtual.avaliacao_finalizada"
                                      class="btn-novo btn-primary mx-1 py-2 px-4 mt-2 text-light"
                                      @click="showModal('modalAvaliacao')">
                                      <span> {{ getUrlParameter("tipo") == 'A' ? 'Revisar Avaliação'
                                        :
                                        'Revisar Recuperação' }} </span>
                                    </a>
                                    <a v-if="fastAtividadeAtual.avaliacao_finalizada"
                                      class="btn-novo btn-danger mx-1 py-2 px-4 mt-2 text-light text-nowrap"
                                      @click="finalizaAvaliacaoTentativa()">
                                      {{ getUrlParameter("tipo") == 'A' ? 'Finalizar avaliação' : 'Finalizar Recuperação'
                                      }}
                                    </a>
                                  </div>
                                  <div v-else class="row d-flex justify-content-center">
                                    <div
                                      v-if="fastAtividadeAtual.count < fastAtividadeAtual.tentativas_maxima || !fastAtividadeAtual.tentativas_maxima || fastAtividadeAtual.tentativas_maxima == '0'"
                                      class="mb-4">
                                      <div v-if="fastAtividadeAtual.refazendo_avaliacao">
                                        <a class="btn-novo btn-primary mx-1 py-2 px-4 mb-3 text-light"
                                          @click="showModal('modalAvaliacao')">
                                          <span> {{ getUrlParameter("tipo") == 'A' ? 'Revisar Avaliação' : 'Revisar Recuperação'}} </span>
                                        </a>
                                        <a class="btn-novo btn-danger mx-1 py-2 px-4 mb-3 text-light text-nowrap"
                                          @click="finalizaAvaliacaoTentativa()">
                                          <span> {{ getUrlParameter("tipo") == 'A' ? 'Finalizar avaliação' : 'Finalizar Recuperação'}} </span>
                                        </a>
                                      </div>
                                      <a v-else class="btn-novo btn-primary mx-1 py-2 px-4 mb-3 text-light"
                                        @click="showModal('modalConfirmarRefazerAvaliacao')">
                                        <span><b-icon-arrow-return-left /> Fazer novamente </span>
                                      </a>
                                    </div>
                                    <div
                                      v-else-if="fastAvaliacao.recuperacao_configurada && fastAvaliacao.id_secretaria_avaliacao_recuperacao && getUrlParameter('tipo') != 'R'"
                                      class="text-center mb-4">
                                      <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + $route.params.id_turma + '/programa/' + $route.params.id_programa_turma + '/avaliacao/' + fastAvaliacao.id_secretaria_avaliacao_recuperacao + '?tipo=R&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                                        class="btn-novo btn-primary mx-1 py-2 px-4 mb-3 text-light"><b-icon-box-arrow-in-down /> Acessar
                                        recuperação</a>
                                    </div>
                                  </div>
                                </div>
                              </div>                              

                              <div class="col-sm-12 col-md-12 col-lg-6 text-center mb-4 px-0 card shadow fd-card-orange-border">
                                <b-list-group>

                                  <b-list-group-item v-if="fastAvaliacaoConfiguracao.final_modulo"
                                    class="d-flex align-items-center p-4 border-transparent">
                                    <b-icon-bar-chart class="mr-4 ml-3" scale="2" />
                                    <span class="mr-auto">Terminar unidade curricular</span>
                                    <h6>{{ fastRequisitosAvaliacao.uc_porcentagem }}%</h6>
                                  </b-list-group-item>

                                  <b-list-group-item v-else-if="fastAvaliacaoConfiguracao.liberar_prazo"
                                    class="d-flex align-items-center px-4 border-transparent">
                                    <img style="max-height: 40px;" class="mr-3 svg-color-fill"
                                      :src="require('@/assets/images/app/Play-rounded-button-outline.svg')">
                                    <span class="mr-auto">Disponível a partir de</span>
                                    <h6><b-badge>{{ getUrlParameter("tipo") == 'A' ?
                                      fastRequisitosAvaliacao.data_disponivel :
                                      fastRequisitosRecuperacao.data_disponivel }}</b-badge></h6>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastAtividadeAtual.tempo_atividade"
                                    class="d-flex align-items-center px-4 border-transparent">
                                    <img style="max-height: 40px;" class="mr-3"
                                      :src="require('@/assets/images/app/provas-tempo-limite.png')">
                                    <span class="mr-auto">Tempo limite</span>
                                    <h6>{{ formataTempoLimite() }}</h6>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastCursoPlataforma.nota_maxima"
                                    class="d-flex align-items-center px-4 border-transparent">
                                    <img style="max-height: 40px;" class="mr-3"
                                      :src="require('@/assets/images/app/provas-nota-maxima.png')"> <span
                                      class="mr-auto">Nota máxima</span>
                                    <h6>{{ fastCursoPlataforma.nota_maxima }}</h6>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastCursoPlataforma.nota_media"
                                    class="d-flex align-items-center px-4 border-transparent">
                                    <img style="max-height: 40px;" class="mr-3"
                                      :src="require('@/assets/images/app/provas-media-avaliacao.png')"> <span
                                      class="mr-auto">Média para avaliação</span>
                                    <h6>{{ fastCursoPlataforma.nota_media }}</h6>
                                  </b-list-group-item>

                                  <b-list-group-item v-if="fastCursoPlataforma.nota_media"
                                    class="d-flex align-items-center px-4 border-transparent">
                                    <img style="max-height: 40px;" class="mr-3"
                                      :src="require('@/assets/images/app/provas-peso-avaliacao.png')"> <span
                                      class="mr-auto">Peso da avaliação</span>
                                    <h6>{{ fastAtividadeAtual.prova_peso ?
                                      fastAtividadeAtual.prova_peso : "Sem peso" }}
                                    </h6>
                                  </b-list-group-item>

                                  <b-list-group-item
                                    class="d-flex align-items-center px-4 border-transparent">
                                    <img style="max-height: 40px;" class="mr-3"
                                      :src="require('@/assets/images/app/provas-tentativas-maximas.png')"> <span
                                      class="mr-auto">Tentativas máximas</span>
                                    <h6>{{ fastAtividadeAtual.tentativas_maxima ?
                                      fastAtividadeAtual.tentativas_maxima : "ilimitada" }}</h6>
                                  </b-list-group-item>

                                  <b-list-group-item
                                    v-if="fastAtividadeAtual.id_secretaria_avaliacao_aluno"
                                    class="d-flex align-items-center px-4 border-transparent">
                                    <img style="max-height: 40px;" class="mr-3"
                                      :src="require('@/assets/images/app/provas-tentativas-maximas.png')"> <span
                                      class="mr-auto">Tentativas realizadas</span>
                                    <h6>{{ fastAtividadeAtual.count ? fastAtividadeAtual.count
                                      : "0" }}
                                    </h6>
                                  </b-list-group-item>

                                  <b-list-group-item
                                    v-if="fastAtividadeAtual.prova_peso && fastAtividadeAtual.id_secretaria_avaliacao_aluno && fastAtividadeAtual.data_fim"
                                    class="d-flex align-items-center pl-3 pr-4 border-transparent">
                                    <img style="max-height: 50px;" class="mr-3"
                                      :src="require('@/assets/images/app/provas-nota.png')"> <span
                                      class="mr-auto">Sua nota</span>
                                    <h6>{{ fastNotaAvaliacao }}</h6>

                                    <h6
                                      v-if="fastNotaAvaliacaoArredondada && fastNotaAvaliacaoArredondada > fastNotaAvaliacao">
                                      <b-badge variant="success" class="text-avaliacao-card ml-1"><b-icon-arrow-up />
                                        {{ fastNotaAvaliacaoArredondada }}</b-badge>
                                    </h6>
                                    <h6
                                      v-else-if="fastNotaAvaliacaoArredondada && fastNotaAvaliacaoArredondada < fastNotaAvaliacao">
                                      <b-badge variant="danger" class="text-avaliacao-card ml-1"><b-icon-arrow-down />
                                        {{ fastNotaAvaliacaoArredondada }}</b-badge>
                                    </h6>
                                  </b-list-group-item>

                                </b-list-group>
                              </div>

                              <div v-if="fastAtividadeAtual.data_fim"
                                class="col-sm-12 col-md-12 col-lg-6 text-center mb-4 pl-lg-4 pl-sm-0">
                                <div v-if="getUrlParameter('tipo') == 'R' && fastAvaliacao.id_curso_uc_recuperacao"
                                  class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <div class="card">
                                      <img :src="imageSitePlataformaImg" alt="Card image cap" class="card-img-top">
                                      <div class="card-body">
                                        <h5 class="card-title text-center">
                                          {{ fastRequisitosRecuperacao.nome_unidade_curricular }} </h5>
                                        <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastAvaliacao.id_curso_uc_recuperacao + '?turma=' + base64encode(parseInt($route.params.id_turma)) + '&programa=' + base64encode(parseInt($route.params.id_programa_turma)) + '&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                                          class="btn-novo btn-primary btn-block"> <b-icon-box-arrow-in-left /> Unidade
                                          curricular</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div v-if="fastAtividadeAtual.data_fim"
                                  class="row h-100">
                                  <div class="col-sm-12 col-md-12 col-lg-12 text-center p-0">
                                    <b-list-group class="shadow fd-card-orange-border h-100">
                                      <b-list-group-item class="h-100">
                                        <div v-if="chartAvaliacaoVisivel" class="d-lg-flex justify-content-center">
                                          <div class="d-flex justify-content-center">
                                            <VueApexCharts width="380" :options="chartOptionsProvaPeso"
                                              :series="chartDataProvaPeso" />
                                          </div>
                                        </div>
                                        <div v-if="chartAvaliacaoVisivel" class="d-lg-flex justify-content-center">
                                          <div class="d-flex justify-content-center">
                                            <VueApexCharts width="380" :options="chartOptions" :series="chartDataProva" />
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </div>
                                </div>

                              </div>

                              <div v-if="fastAtividadeAtual.data_fim"
                                class="col-sm-12 col-md-12 col-lg-12 text-center mb-4 shadow p-0 fd-card-orange-border">
                                <div class="row d-flex justify-content-between">
                                  <div class="col-12">
                                    <b-list-group>
                                      <b-list-group-item v-if="chartAvaliacaoVisivel && fastAtividadeAtual.data_fim"
                                        class="d-flex align-items-center p-4">
                                        <div class="mr-auto">
                                          <img style="max-height: 40px;" class="mr-3"
                                            :src="require('@/assets/images/app/provas-gabarito.png')">
                                          <span>Gabarito</span>
                                        </div>
                                        <a @click="provaDownloadGabarito()"
                                          class="btn-novo btn-success mx-1 py-2 px-4 text-light">
                                          <small><b-icon-cloud-arrow-down /> Download</small>
                                        </a>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </div>
                                  <div v-if="!fastPathGabarito && fastAtividadeAtual.data_fim" class="col-12">
                                    <uploader :file-status-text="statusText" :options="optionsArquivoGabarito"
                                      class="uploader-example" @file-success="fileSuccessGabarito"
                                      @file-added="fileValidation">
                                      <uploader-unsupport />
                                      <uploader-drop>
                                        <p>Enviar gabarito assinado (somente pdf, jpg, jpeg, gif ou png)</p>
                                        <uploader-btn :attrs="restrictArquivo">
                                          Clique aqui para selecionar
                                        </uploader-btn>
                                      </uploader-drop>
                                      <uploader-list />
                                    </uploader>
                                  </div>
                                  <div v-if="fastPathGabarito" class="col-12 mt-2">
                                    <a :href="fastPathGabarito" target="_blank">
                                      <h4>Gabarito assinado</h4>
                                    </a>
                                    <button class="btn btn-sm btn-primary" @click="fastPathGabarito = ''">
                                      <b-icon-arrow-counterclockwise font-scale="1" /> Reenviar
                                    </button>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
    <modal name="modalAvaliacao" :shift-y="0.1" height="auto" :width="modalWidthLg" :scrollable="true" :adaptative="true">
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Avaliação</h4>
            </div>
            <div class="col-6 text-right">
              <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalAvaliacao')">
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 text-info">
              <small>Para alternar entre as perguntas basta arrastar!</small>
            </div>
          </div>
        </div>
        <div
          v-if="fastAtividadeAtual.data_previsto_fim && fastAtividadeAtual.tempo_atividade && fastAtividadeAtual.tempo_atividade != '00:00:00'"
          class="col-12 d-flex justify-content-center">
          <fast-timer :endtime="fastAtividadeAtual.data_previsto_fim" trans='{  
                    "day":"Dias",
                    "hours":"Horas",
                    "minutes":"Minutos",
                    "seconds":"Segundos",
                    "running":"",
                    "upcoming":"",
                    "status": {
                        "expired":"",
                        "running":"",
                        "upcoming":""
                      }}'></fast-timer>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 pl-lg-4 pr-lg-4">
              <div class="row pl-2 pr-2 pb-lg-4">
                <carousel v-if="isMobile()" :navigation-enabled="true" :per-page="1" :touch-drag="true" :mouse-drag="true"
                  :adjustable-height="true" :pagination-size="20" :paginationEnabled="false" :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded">
                  <slide v-for="(q, index) in fastAtividadeAtual.avaliacao" :key="q.id_questao">
                    <div class="col-12 mt-2 max-width-100vw">
                      <h5>
                        Questão {{ index + 1 }} de
                        {{ fastAtividadeAtual.avaliacao.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2">
                      <div v-html="q.pergunta" />
                    </div>
                    <div v-if="q.alternativas.length" class="col-12 p-2 max-width-100vw">
                      <div v-for="(a, index2) in q.alternativas" :key="a.id_alternativa" class="row pl-2 pr-2">
                        <div class="col-12">
                          <div v-if="a.opcao_escolhida == a.id_alternativa"
                            :class="'d-flex fast-aula-alternativa-escolhida'">
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div v-else :class="'d-flex fast-aula-alternativa'" @click="respondeQuestaoAvaliacao(q, a)">
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>

                <carousel v-else :navigation-enabled="true" :per-page="1" :touch-drag="true" :mouse-drag="true"
                  :adjustable-height="true" :pagination-size="20" :paginationEnabled="true" :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded">
                  <slide v-for="(q, index) in fastAtividadeAtual.avaliacao" :key="q.id_questao">
                    <div class="col-12 mt-2 max-width-100vw">
                      <h5>
                        Questão {{ index + 1 }} de
                        {{ fastAtividadeAtual.avaliacao.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2">
                      <div v-html="q.pergunta" />
                    </div>
                    <div v-if="q.alternativas.length" class="col-12 p-2 max-width-100vw">
                      <div v-for="(a, index2) in q.alternativas" :key="a.id_alternativa" class="row pl-2 pr-2">
                        <div class="col-12">
                          <div v-if="a.opcao_escolhida == a.id_alternativa"
                            :class="'d-flex fast-aula-alternativa-escolhida'">
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div v-else :class="'d-flex fast-aula-alternativa'" @click="respondeQuestaoAvaliacao(q, a)">
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>


    <modal name="modalResultadoAviso" :shift-y="0.1" height="auto" :width="modalWidthSm" :scrollable="true"
      :adaptative="true">
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-12 text-right">
              <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalResultadoAviso')">
                <b-icon-arrow-return-left /> fechar
              </a>
            </div>
            <div class="col-12 mt-4 text-center">
              <b-alert v-if="fastUsuarioResultado.aprovado" show variant="success">
                <h4 class="alert-heading">Parabéns!</h4>
                <p>
                  Você atingiu a média da avaliação.
                </p>
                <hr>
                <h1>
                  {{ fastNotaAvaliacaoArredondada ? fastNotaAvaliacaoArredondada :
                    fastNotaAvaliacao }}/{{ fastCursoPlataforma.nota_media }}
                </h1>
              </b-alert>
              <b-alert
                v-else-if="fastAtividadeAtual.count < fastAtividadeAtual.tentativas_maxima || !fastAtividadeAtual.tentativas_maxima || fastAtividadeAtual.tentativas_maxima == '0'"
                show variant="warning">
                <h4 class="alert-heading">Média não atingida!</h4>
                <p>
                  Você não atingiu a média da avaliação, porém possui mais {{ fastAtividadeAtual.tentativas_maxima -
                    fastAtividadeAtual.count }} tentativa(s)
                </p>
                <hr>
                <h1>
                  {{ fastNotaAvaliacaoArredondada ? fastNotaAvaliacaoArredondada :
                    fastNotaAvaliacao }}/{{ fastCursoPlataforma.nota_media }}
                </h1>
              </b-alert>
              <b-alert v-else show variant="danger">
                <h4 class="alert-heading">Média não atingida!</h4>
                <p>
                  Você não atingiu a média da avaliação.
                </p>
                <hr>
                <h1>
                  {{ fastNotaAvaliacaoArredondada ? fastNotaAvaliacaoArredondada :
                    fastNotaAvaliacao }}/{{ fastCursoPlataforma.nota_media }}
                </h1>
                <div v-if="fastAvaliacao.recuperacao_configurada && fastAvaliacao.id_secretaria_avaliacao_recuperacao">
                  <hr>
                  <p>
                    A recuperação está disponível para esta unidade curricular.
                  </p>
                  <p>
                    <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + $route.params.id_turma + '/programa/' + $route.params.id_programa_turma + '/avaliacao/' + fastAvaliacao.id_secretaria_avaliacao_recuperacao + '?tipo=R&matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                      class="btn btn-primary">Acesse aqui</a>
                  </p>
                </div>


              </b-alert>

            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modalConfirmarRefazerAvaliacao" :shift-y="0.1" height="auto" :width="modalWidthSm" :scrollable="true"
      :adaptative="true">
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Tem certeza que deseja realizar uma nova tentativa?</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalConfirmarRefazerAvaliacao')">
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6 class="text-danger">
                A nota dessa tentativa irá substituir a nota atual.
              </h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button href="#" type="button" class="btn btn-danger" @click.prevent="iniciarAvaliacao('refazer')">
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <VueHtml2pdf ref="html2Pdf" class="mx-auto" :show-layout="false" :float-layout="true" :enable-download="true"
      :preview-modal="false" :html-to-pdf-options="htmlToPdfOptions" pdf-content-width="960px" :manual-pagination="false"
      :paginate-elements-by-height="10000">
      <section slot="pdf-content">
        <div v-html="htmlProvaGabarito" />
      </section>
    </VueHtml2pdf>

    <img id="logo_plataforma" :src="logo_plataforma" class="d-none">

    <notifications group="foo" position="bottom right" />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import muralAvisos from "../components/MuralAvisos";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";
import VueApexCharts from "vue-apexcharts"; //https://github.com/apexcharts/vue-apexcharts https://apexcharts.com/vue-chart-demos
import FastTimer from "../../components/FastTimer"
import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme

export default {
  name: "HomeInternoAlunoTurmaProgramaAvaliacoes",
  components: {
    muralAvisos, VueApexCharts, FastTimer, VueHtml2pdf, Carousel, Slide
  },
  mixins: [methods],
  data: function () {
    return {
      imageSitePlataformaImg,
      fastTemplate: settings.fastTemplate,
      nome_plataforma: this.retornaSessao(settings.fastSessaoPlataforma).nome_plataforma,
      logo_plataforma: this.retornaSessao(settings.fastSessaoPlataforma).logo_plataforma,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTurma: {
        modulos: [],
        carregandoModulos: true
      },
      fastPrograma: {
        id_programa_turma: 0,
        sigla_programa: "",
        unidades: [],
        carregandoUnidades: true
      },
      fastTipoAvaliacao: "",
      fastAvaliacao: {
        avaliacao_configurada: true,
        carga_horaria: null,
        carga_horaria_mae: 0,
        count_avaliacao: null,
        count_recuperacao: null,
        creditos: 10,
        data_fim_avaliacao: null,
        data_fim_recuperacao: null,
        data_final: "2021-12-17",
        data_inicio: "2021-03-01",
        data_inicio_avaliacao: null,
        data_inicio_recuperacao: null,
        data_previsto_fim_avaliacao: null,
        data_previsto_fim_recuperacao: null,
        data_previsto_inicio_avaliacao: null,
        data_previsto_inicio_recuperacao: null,
        detalhe_avaliacao: "",
        detalhe_curso: null,
        detalhe_curso_mae: "",
        detalhe_recuperacao: "",
        id_curso: 0,
        id_curso_uc_recuperacao: 0,
        id_pai: null,
        id_plano_ensino: 0,
        id_plataforma_origem: 0,
        id_programa_turma_unidade: 0,
        id_secretaria_avaliacao_aluno_avaliacao: null,
        id_secretaria_avaliacao_principal: 0,
        id_secretaria_avaliacao_recuperacao: 0,
        id_secretaria_curso_programa: 0,
        id_secretaria_curso_programa_unidade: 1,
        id_secretaria_recuperacao_aluno_avaliacao: null,
        nome_curso: "",
        nome_curso_mae: "",
        nota_final_avaliacao: null,
        nota_final_recuperacao: null,
        path_gabarito_avaliacao: null,
        path_gabarito_recuperacao: null,
        preco: 100,
        recuperacao_configurada: true,
        sequencia: 1,
        tempo_atividade_avaliacao: null,
        tempo_atividade_recuperacao: null,
        tempo_executado_avaliacao: null,
        tempo_executado_recuperacao: null,
        tempo_limite_avaliacao: "01:00:00",
        tempo_limite_recuperacao: "01:00:00",
        tentativas_maxima_avaliacao: 1,
        tentativas_maxima_avaliacao_aluno: null,
        tentativas_maxima_recuperacao: 1,
        tentativas_maxima_recuperacao_aluno: null,
        tipo_avaliacao: "",
        titulo_avaliacao: "",
        titulo_recuperacao: ""
      },
      fastAvaliacaoConfiguracao: {
        final_modulo: false,
        id_avaliacao_configuracao: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_resultado_passar_direto: 0,
        id_secretaria_avaliacao_uc: 0,
        liberar_prazo: 0,
        media_aprovacao: 0,
        media_recuperacao: 0,
        nota_maxima: 0,
        passar_direto: 0,
        tipo: "",
      },
      fastAvaliacaoConfiguracaoArredondamentos: [],
      fastLoadingAvalicao: true,
      fastAtividadeAtual: {
        id_secretaria_avaliacao_aluno: 0,
        data_fim: "",
        data_inicio: "",
        data_previsto_fim: "",
        data_previsto_inicio: "",
        detalhe_atividade: "",
        id_apostila: "",
        id_aula: "",
        id_curso: 0,
        id_usuario: "",
        id_usuario_atividade: "",
        id_video: "",
        incrementa_evolucao: "",
        publicada: "",
        referencia_atividade: "",
        sequencia: "",
        tempo_atividade: "",
        tempo_executado: "",
        tipo_atividade: "",
        titulo_atividade: "",
        tentativas_maxima: "",
        count: "",
        refazendo_avaliacao: "",
        quiz: [],
        pesquisa: [],
        avaliacao: [],
        uploads: [],
        pesquisa_finalizada: false,
        pesquisas_pendentes: false,
        avaliacao_finalizada: false,
        avaliacoes_pendentes: false,
        refazendo_avaliacao: false,
      },
      fastCursoPlataforma: {
        acesso: "",
        carga_horaria: "",
        data_disponivel: "",
        data_expiracao: "",
        detalhe_curso: "",
        id_curso: 0,
        id_plataforma: 0,
        id_registro_curso_plataforma: "",
        id_requisicao: "",
        id_usuario_responsavel: "",
        libera_automatica: "",
        logo_curso: "",
        multidisciplinar: "",
        nome_curso: "",
        origem: "",
        publicado: "",
        quantidade: "",
        nota_maxima: "",
        nota_media: "",
        nome_curso_mae: ""
      },
      // Chart
      chartOptions: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Acertos", "Erros"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsProvaPeso: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Nota atingida", "Nota restante"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      fastNotaAvaliacao: 0.00,
      fastNotaAvaliacaoArredondada: 0.00,
      fastPesoAvaliacao: "",
      chartDataQuiz: [0, 0],
      chartDataProva: [0, 0],
      chartDataProvaPeso: [0, 0],
      chartAvaliacaoVisivel: true,
      fastAvaliacaoTempoIniciada: false,
      countDownSettings: {
        days: "Dias",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
      },
      // Gabarito
      fastPathGabarito: "",
      htmlProvaGabarito: "",
      htmlToPdfOptions: {
        margin: 0.5,
        filename: 'Gabarito.pdf',
        image: {
          type: 'jpeg',
          quality: 1
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          scrollX: 0,
          scrollY: 0,
          useCORS: true,
          width: 960,
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait'
        }
      },
      optionsArquivoGabarito: {
        target: settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/upload_gabarito",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: false,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_secretaria_avaliacao_aluno: 0
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png", "rar"],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept: "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      fastRequisitosAvaliacao: {
        data_disponivel: "",
        data_iniciou: "",
        disponivel: false,
        uc_porcentagem: 0
      },
      fastRequisitosRecuperacao: {
        data_disponivel: "",
        data_iniciou: "",
        disponivel: false,
        uc_porcentagem: 0,
        nome_unidade_curricular: "Conteúdo de recuperação"
      },
      fastUsuarioResultado: {
        aprovado: false,
        resultado: ""
      }
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_turma && this.$route.params.id_programa_turma && this.$route.params.id_secretaria_avaliacao && this.getUrlParameter("matricula") && this.getUrlParameter("tipo")) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.fastTipoAvaliacao = this.getUrlParameter("tipo")

          this.getFastCursosPrograma(this.$route.params.id_plataforma, this.$route.params.id_programa_turma)
          this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.base64decode(this.getUrlParameter("matricula")))

          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          console.log(e)
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.exibeToasty("Sem permissões", "error");
      this.$store.state.fastCarregando = false;
    }
  },
  methods: {
    async getFastMatriculaAluno(id_plataforma, id_matricula) {
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_aluno", "id_matricula=" + id_matricula + "&id_plataforma=" + id_plataforma).then(obj => {
        this.fastMatriculaAluno = obj;
        this.getFastTurma(this.$route.params.id_plataforma, this.$route.params.id_turma)
      }).catch(e => {
        console.log(e);
      })
    },
    async getFastTurma(id_plataforma, id_turma) {
      this.fastTurma.carregandoModulos = true;
      this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
        if (obj.length) {
          obj[0].modulos = [];
          obj[0].carregandoModulos = true
          this.fastTurma = obj[0]
        } else {
          this.fastTurma.carregandoModulos = false;
        }

      }).then(() => {
        this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
          if (obj.length) {
            obj.forEach((m, index) => {
              if (m.id_programa_turma == this.$route.params.id_programa_turma) {
                this.fastPrograma.id_programa_turma = m.id_programa_turma
                this.fastPrograma.sigla_programa = m.sigla_programa
              }
              this.fastMatriculaAluno.forEach(m2 => {
                if (m.id_programa_turma == m2.id_programa_turma) {
                  obj[index].permitido = true
                }
              })
            })
            this.fastTurma.modulos = obj
          } else {
            this.fastTurma.modulos = [];
          }
          this.fastTurma.carregandoModulos = false;
        }).catch(e => {
          console.log(e);
        })
      })
        .catch(e => {
          console.log(e);
        })
    },
    async getFastCursosPrograma(id_plataforma, id_programa_turma) {
      this.fastPrograma.carregandoUnidades = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad +
          "api/fast_secretaria_turma_programa_unidade/lista_avs?id_plataforma=" +
          id_plataforma +
          "&id_programa_turma=" +
          id_programa_turma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((e) => {
            //if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0]
            //if (e.data_final) e.data_final = e.data_final.split("T")[0]

            if (e.data_inicio_avaliacao) e.data_inicio_avaliacao = e.data_inicio_avaliacao.split("T")[0]
            if (e.data_fim_avaliacao) e.data_fim_avaliacao = e.data_fim_avaliacao.split("T")[0]

            if (e.data_inicio_recuperacao) e.data_inicio_recuperacao = e.data_inicio_recuperacao.split("T")[0]
            if (e.data_fim_recuperacao) e.data_fim_recuperacao = e.data_fim_recuperacao.split("T")[0]

            if (this.fastTipoAvaliacao == "A" && e.id_secretaria_avaliacao_principal == this.$route.params.id_secretaria_avaliacao) {
              this.fastAvaliacao = e
              console.log("this.fastAvaliacao", e)

              if (!e.id_secretaria_avaliacao_aluno_avaliacao) this.nunca_inicializada = true

              this.fastAtividadeAtual.id_secretaria_avaliacao_aluno = this.fastAvaliacao.id_secretaria_avaliacao_aluno_avaliacao
              this.optionsArquivoGabarito.query.id_secretaria_avaliacao_aluno = this.fastAvaliacao.id_secretaria_avaliacao_aluno_avaliacao
              this.fastAtividadeAtual.titulo_atividade = this.fastAvaliacao.titulo_avaliacao
              this.fastAtividadeAtual.detalhe_atividade = this.fastAvaliacao.detalhe_avaliacao
              this.fastAtividadeAtual.tempo_atividade = this.fastAvaliacao.tempo_limite_avaliacao
              this.fastAtividadeAtual.tentativas_maxima = this.fastAvaliacao.tentativas_maxima_avaliacao_aluno ? this.fastAvaliacao.tentativas_maxima_avaliacao_aluno : this.fastAvaliacao.tentativas_maxima_avaliacao
              this.fastAtividadeAtual.count = this.fastAvaliacao.count_avaliacao
              this.fastAtividadeAtual.data_previsto_fim = this.fastAvaliacao.data_previsto_fim_avaliacao
              this.fastAtividadeAtual.data_inicio = this.fastAvaliacao.data_inicio_avaliacao
              this.fastAtividadeAtual.data_fim = this.fastAvaliacao.data_fim_avaliacao

              this.getAvaliacaoConfiguracoes(e.id_secretaria_avaliacao_uc)



            } else if (this.fastTipoAvaliacao == "R" && e.id_secretaria_avaliacao_recuperacao == this.$route.params.id_secretaria_avaliacao) {
              this.fastAvaliacao = e

              if (!e.id_secretaria_avaliacao_aluno_recuperacao) this.nunca_inicializada = true

              this.fastAtividadeAtual.id_secretaria_avaliacao_aluno = this.fastAvaliacao.id_secretaria_recuperacao_aluno_avaliacao
              this.optionsArquivoGabarito.query.id_secretaria_avaliacao_aluno = this.fastAvaliacao.id_secretaria_recuperacao_aluno_avaliacao
              this.fastAtividadeAtual.titulo_atividade = this.fastAvaliacao.titulo_recuperacao
              this.fastAtividadeAtual.detalhe_atividade = this.fastAvaliacao.detalhe_recuperacao
              this.fastAtividadeAtual.tempo_atividade = this.fastAvaliacao.tempo_limite_recuperacao
              this.fastAtividadeAtual.tentativas_maxima = this.fastAvaliacao.tentativas_maxima_recuperacao_aluno ? this.fastAvaliacao.tentativas_maxima_recuperacao_aluno : this.fastAvaliacao.tentativas_maxima_recuperacao
              this.fastAtividadeAtual.count = this.fastAvaliacao.count_recuperacao
              this.fastAtividadeAtual.data_previsto_fim = this.fastAvaliacao.data_previsto_fim_recuperacao
              this.fastAtividadeAtual.data_inicio = this.fastAvaliacao.data_inicio_recuperacao
              this.fastAtividadeAtual.data_fim = this.fastAvaliacao.data_fim_recuperacao

              this.getAvaliacaoConfiguracoes(e.id_secretaria_avaliacao_uc)


            }
          });

          console.log("this.fastAtividadeAtual", this.fastAtividadeAtual)

          this.stepList = obj.map((e) => {
            return e.sequencia + " - " + e.nome_curso;
          });
          this.fastPrograma.unidades = obj;

          if (this.fastTipoAvaliacao == "A" && !this.fastAvaliacao.id_secretaria_avaliacao_principal) {
            this.exibeToasty("Não foi possível carregar a avaliação", "error")
          } else if (this.fastTipoAvaliacao == "R" && !this.fastAvaliacao.id_secretaria_avaliacao_recuperacao) {
            this.exibeToasty("Não foi possível carregar a recuperação", "error")
          }


          this.getPesoAvaliacao()
          // Continua avaliação
          if (this.fastAtividadeAtual.data_inicio && !this.fastAtividadeAtual.data_fim) {
            if (this.fastAtividadeAtual.data_previsto_fim)
              if (this.fastAtividadeAtual.data_previsto_fim.indexOf("T") != "-1") this.fastAtividadeAtual.data_previsto_fim = this.fastAtividadeAtual.data_previsto_fim.split("T")[0] + " " + this.fastAtividadeAtual.data_previsto_fim.split("T")[1].split(".")[0];

            // Hack para timer
            let startTimeEpoch = new Date(this.fastAtividadeAtual.data_previsto_fim).getTime() / 1000
            let d = new Date(0);
            d.setUTCSeconds(startTimeEpoch);
            let month = d.getMonth() + 1;
            let day = d.getDate();
            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            if (this.fastAtividadeAtual.data_previsto_fim)
              this.fastAtividadeAtual.data_previsto_fim = d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[1] + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[2];

            this.iniciarAvaliacao("continuar");
            // Finalizou
          } else if (this.fastAtividadeAtual.data_fim) {

            this.optionsArquivoGabarito.query.id_secretaria_avaliacao_aluno = this.fastAtividadeAtual.id_secretaria_avaliacao_aluno

            this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno/gabarito", "id_secretaria_avaliacao_aluno=" + this.fastAtividadeAtual.id_secretaria_avaliacao_aluno)
              .then(obj => {
                if (obj.length) this.fastPathGabarito = obj[0].path_gabarito
                else
                  this.fastPathGabarito = ""
              }).catch(e => {
                console.log(e);
                this.fastPathGabarito = ""
              })

          }
        } else {
          this.fastPrograma.unidades = [];
        }

        this.fastPrograma.carregandoUnidades = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async buscaDadosUnidadeCurricular(id_curso) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
            "api/fast_usuario_curso/lista?id_curso=" +
            id_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json);
          } else {
            reject(0);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    formataTempoLimite() {
      let horas = parseInt(this.fastAtividadeAtual.tempo_atividade.split(":")[0]);
      let minutos = parseInt(this.fastAtividadeAtual.tempo_atividade.split(":")[1]);
      let tempo_limite = "";
      if (horas) {
        if (horas == 1) tempo_limite = "1 hora";
        else tempo_limite = horas + " horas";
        if (minutos) {
          tempo_limite += " e " + minutos + " minutos";
        }
      } else if (minutos) {
        tempo_limite = minutos + " minutos";
      } else {
        tempo_limite = "Ilimitado";
      }

      return tempo_limite;
    },
    async getAvaliacaoConfiguracoes(id_secretaria_avaliacao_uc) {
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_configuracao/lista", "id_secretaria_avaliacao_uc=" + id_secretaria_avaliacao_uc)
        .then(obj => {
          console.log("this.fastAvaliacaoConfiguracao", obj)
          if (obj.length) {
            obj.forEach(c => {
              if (c.tipo == this.fastTipoAvaliacao) {
                this.fastAvaliacaoConfiguracao = c
                this.fastCursoPlataforma.nota_maxima = c.nota_maxima
                this.fastCursoPlataforma.nota_media = this.fastTipoAvaliacao == "A" ? c.media_aprovacao : c.media_recuperacao
                this.fastLoadingAvalicao = false
                this.getAvaliacaoArredondamento(c.id_avaliacao_configuracao)

                this.fastRequisitosAvaliacao.disponivel = false
                this.calculaDiasPrazo()
                this.getFastUcPorcentagem()
              }

            })
          } else {
            this.exibeToasty("Nenhuma configuração encontrada", "error")
          }
        }).catch(e => {
          this.exibeToasty("Erro ao buscar configurações da avaliação", "error")
          console.log(e)
        })
    },
    async getAvaliacaoArredondamento(id_avaliacao_configuracao) {
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_arredondamento/lista", "id_avaliacao_configuracao=" + id_avaliacao_configuracao)
        .then(res => {
          if (res.length) {
            this.fastAvaliacaoConfiguracaoArredondamentos = res
          }
          else {
            this.fastAvaliacaoConfiguracaoArredondamentos = []
          }

          if (this.fastAtividadeAtual.data_fim) {
            this.getResultadoAvaliacao()
          }

        }).catch(e => {
          this.exibeToasty("Erro ao buscar arredondamentos", "error")
          console.log(e)
        })
    },
    async calculaDiasPrazo() {
      let data_permitida = ""

      if (this.getUrlParameter("tipo") == "A") {
        this.buscaDadosUnidadeCurricular(this.fastAvaliacao.id_curso).then(u => {
          if (u.length) {
            u = u[0]
            if (u.data_inicio) this.fastRequisitosAvaliacao.data_iniciou = u.data_inicio.split("T")[0]

          }
        }).then(() => {
          if (this.fastRequisitosAvaliacao.data_iniciou) {
            let inicio = new Date(this.fastRequisitosAvaliacao.data_iniciou.split('-')[1] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[2] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[0]);
            data_permitida = this.addDays(inicio, this.fastAvaliacaoConfiguracao.liberar_prazo)

            this.getFastHoraServidor().then(() => {
              let dataAtual = this.$store.state.fastDataAtualServidor
              if (dataAtual > data_permitida && !this.fastAvaliacaoConfiguracao.final_modulo) {
                this.fastRequisitosAvaliacao.disponivel = true
              }
              this.fastRequisitosAvaliacao.data_disponivel = this.dataFormatada(data_permitida)

            })

          } else {
            this.fastRequisitosAvaliacao.data_disponivel = "iniciar UC"
          }
        }).catch(e => {
          this.fastRequisitosAvaliacao.data_disponivel = "iniciar UC"
        })
      } else {
        if (this.fastAvaliacao.id_curso_uc_recuperacao) {
          this.buscaDadosUnidadeCurricular(this.fastAvaliacao.id_curso_uc_recuperacao).then(u => {
            if (u.length) {
              u = u[0]
              if (u.data_inicio) this.fastRequisitosRecuperacao.data_iniciou = u.data_inicio.split("T")[0]
            }


          }).then(() => {
            if (this.fastRequisitosRecuperacao.data_iniciou) {
              let inicio = new Date(this.fastRequisitosAvaliacao.data_iniciou.split('-')[1] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[2] + '-' + this.fastRequisitosAvaliacao.data_iniciou.split('-')[0]);
              data_permitida = this.addDays(inicio, this.fastAvaliacaoConfiguracao.liberar_prazo)


              this.getFastHoraServidor().then(() => {
                let dataAtual = this.$store.state.fastDataAtualServidor
                if (dataAtual > data_permitida && !this.fastAvaliacaoConfiguracao.final_modulo) {
                  this.fastRequisitosRecuperacao.disponivel = true
                }
                this.fastRequisitosRecuperacao.data_disponivel = this.dataFormatada(data_permitida)
              })

            } else {
              this.fastRequisitosRecuperacao.data_disponivel = "iniciar UC"
            }
          }).catch(e => {
            this.fastRequisitosRecuperacao.data_disponivel = "iniciar UC"
          })
        }

      }


    },
    // Avaliação
    async promiseIniciaAtividadeAtual() {
      return new Promise(async (resolve, reject) => {
        // Se for avaliação que não finalizou
        if (this.fastAtividadeAtual.data_inicio && !this.fastAtividadeAtual.data_fim) {
          resolve(true);
        } else {
          let id_curso = this.fastAvaliacao.id_curso
          //if (this.getUrlParameter("tipo") == 'R') id_curso = this.fastAvaliacao.id_curso_uc_recuperacao

          let id_secretaria_avaliacao_aluno = this.fastAvaliacao.id_secretaria_avaliacao_aluno_avaliacao
          if (this.getUrlParameter("tipo") == 'R') id_secretaria_avaliacao_aluno = this.fastAvaliacao.id_secretaria_recuperacao_aluno_avaliacao

          let titulo_avaliacao = this.fastAvaliacao.titulo_avaliacao
          if (this.getUrlParameter("tipo") == 'R') titulo_avaliacao = this.fastAvaliacao.titulo_recuperacao

          let obj = {
            tipo: this.getUrlParameter("tipo"),
            fast_secretaria_avaliacao_aluno: {
              id_secretaria_avaliacao: this.$route.params.id_secretaria_avaliacao,
              id_secretaria_curso_programa_unidade: this.fastAvaliacao.id_secretaria_curso_programa_unidade,
              id_plataforma: this.$route.params.id_plataforma,
              id_secretaria_avaliacao_aluno: id_secretaria_avaliacao_aluno
            },
            titulo_avaliacao: titulo_avaliacao,
            id_programa_turma: this.$route.params.id_programa_turma,
            id_curso: id_curso
          };
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/atualiza_data_inicio", this.fastAjaxOptions("POST", JSON.stringify(obj)));
            let json = await resp.json();
            let res = Array.from(json);
            if (res.length) {
              let a = res[0]
              this.fastAtividadeAtual.id_secretaria_avaliacao_aluno = a.id_secretaria_avaliacao_aluno
              this.optionsArquivoGabarito.query.id_secretaria_avaliacao_aluno = a.id_secretaria_avaliacao_aluno
              this.fastAtividadeAtual.data_inicio = a.data_inicio;
              if (a.data_previsto_fim) {
                this.fastAtividadeAtual.data_previsto_fim = a.data_previsto_fim.split("T")[0] + " " + a.data_previsto_fim.split("T")[1].split(".")[0];


                // Hack para timer
                let startTimeEpoch = new Date(this.fastAtividadeAtual.data_previsto_fim).getTime() / 1000
                let d = new Date(0);
                d.setUTCSeconds(startTimeEpoch);
                let month = d.getMonth() + 1;
                let day = d.getDate();
                if (month < 10) month = "0" + month;
                if (day < 10) day = "0" + day;
                this.fastAtividadeAtual.data_previsto_fim =
                  d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[1] + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[2];

              }

              resolve(true);
            } else {
              reject(false);
            }
          } catch (e) {
            this.exibeToasty(e, "error");
            console.log(e)
            reject(false);
          }
        }
      });
    },
    async promiseGetAvaliacaoQuestoes() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/questoes/lista_perguntas?id_secretaria_avaliacao=" + this.$route.params.id_secretaria_avaliacao + "&id_secretaria_avaliacao_aluno=0&id_secretaria_curso_programa_unidade=" + this.fastAvaliacao.id_secretaria_curso_programa_unidade + "&id_plataforma=" + this.$route.params.id_plataforma + "&tipo=" + this.getUrlParameter("tipo"), this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async respondeQuestaoAvaliacao(questao, alternativa) {
      questao.alternativas.forEach((a) => {
        if (a.id_alternativa != alternativa.id_alternativa) a.opcao_escolhida = null;
        else a.opcao_escolhida = a.id_alternativa;
      });

      let fast_secretaria_avaliacao_questoes_aluno = {
        id_secretaria_avaliacao_aluno: this.fastAtividadeAtual.id_secretaria_avaliacao_aluno,
        id_questao: questao.id_questao,
        opcao_escolhida: alternativa.id_alternativa,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/questoes/responde", this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_avaliacao_questoes_aluno)));
        let json = await resp.json();
        let obj = Array.from(json);
        //obj.forEach((q) => {
        //if (questao.id_questao == q.id_questao) {
        questao.resolvida = "S";
        //}
        //});
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Resposta salva com sucesso",
        });
        this.verificaAvaliacoesPendentes("respondeu");
      } catch (e) {
        this.exibeToasty(e, "error");
        console.log(e)
      }
    },
    verificaAvaliacoesPendentes(acao) {
      if (this.fastAtividadeAtual.avaliacao) {
        let totalQuestoes = this.fastAtividadeAtual.avaliacao.length;
        let totalQuestoesRespondida = 0;
        this.fastAtividadeAtual.avaliacao.forEach((q) => {
          if (q.resolvida == "S") totalQuestoesRespondida++;
        });

        switch (acao) {
          case "continuar":
            // Questões pendentes e avaliação não finalizou
            /*if (
              totalQuestoes > totalQuestoesRespondida &&
              totalQuestoesRespondida > 0 && !this.fastAtividadeAtual.data_fim
            ) {*/
            this.fastAtividadeAtual.avaliacoes_pendentes = totalQuestoes - totalQuestoesRespondida;

            if (totalQuestoes == totalQuestoesRespondida) {
              this.fastAtividadeAtual.avaliacao_finalizada = true;
              // Se existem questões pendentes
            } else if (totalQuestoes > totalQuestoesRespondida && totalQuestoesRespondida > 0) {
              this.fastAtividadeAtual.avaliacao_finalizada = false;
            }

            this.showModal("modalAvaliacao");

            if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00") {
              //console.log('if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00")')
              this.verificaTempo();
            }
            //}
            break;

          case "respondeu":
            // Se todas as questões foram respondidas e avaliação não finalizou
            if (totalQuestoes == totalQuestoesRespondida) {
              this.fastAtividadeAtual.avaliacao_finalizada = true;
              this.fastAtividadeAtual.avaliacoes_pendentes = false;
              this.hideModal("modalAvaliacao");
              // Se existem questões pendentes
            } else if (totalQuestoes > totalQuestoesRespondida && totalQuestoesRespondida > 0) {
              this.fastAtividadeAtual.avaliacao_finalizada = false;
              this.fastAtividadeAtual.avaliacoes_pendentes = true;
            }
            // this.verificaTempo();
            break;
          case "finalizou":
            this.fastAtividadeAtual.avaliacao_finalizada = true;
            this.fastAtividadeAtual.avaliacoes_pendentes = false;
            this.fastAtividadeAtual.refazendo_avaliacao = false;
            this.getResultadoAvaliacao();
            this.hideModal("modalAvaliacao");
            break;
          default:
            break;
        }
      } else {
        this.fastFinalizandoAvaliacao = false;
      }
    },
    async iniciarAvaliacao(acao) {
      this.chartAvaliacaoVisivel = false;


      this.promiseIniciaAtividadeAtual()
        .then(() => {
          this.exibeToasty("Buscando questões", "info");
          this.fastLoadingAvalicao = true;
        })
        .then(() => {
          return this.promiseGetAvaliacaoQuestoes();
        })
        .then((obj) => {

          if (obj.length) {
            // Separa as questões e alternativas
            const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
              return obj.find((a) => a.id_questao === id_questao);
            });
            questoesUnicas.forEach((a, index) => {
              obj.forEach((q, index2) => {
                if (a.id_questao == q.id_questao) {
                  if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                  questoesUnicas[index].alternativas.push(q);
                }
              });
            });
            // passando dentro de questoesUnicas para tirar o "&nbsp" de pergunta e alternativa
            questoesUnicas.forEach(a => {
              /*
              a.pergunta = a.pergunta.replaceAll("&nbsp;"," ");
                a.alternativas.forEach(q => {
                  q.alternativa = q.alternativa.replaceAll("&nbsp;", " ")
              })
              */

              // Atualização (replaceAll é incompatível em sistemas mais antigos)
              a.pergunta = a.pergunta.replace(/&nbsp;/g, ' ');
              a.alternativas.forEach(q => {
                q.alternativa = q.alternativa.replace(/&nbsp;/g, ' ')
              })

            })
            this.fastAtividadeAtual.avaliacao = questoesUnicas;


            this.$toast.clear();
            this.fastLoadingAvalicao = false;

            switch (acao) {
              case "iniciar":
                this.showModal("modalAvaliacao");
                if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00") {
                  this.verificaTempo();
                }
                break;
              case "continuar":
                // Verifica se as questões já foram respondidas
                this.fastAtividadeAtual.avaliacoes_pendentes = true;
                this.verificaAvaliacoesPendentes(acao);
                break;
              case "refazer":

                this.fastAtividadeAtual.refazendo_avaliacao = true;
                this.fastAtividadeAtual.avaliacao.forEach((q) => {
                  q.resolvida = null;
                  q.acertou = null;
                  q.alternativas.forEach((a) => {
                    a.resolvida = null;
                    a.opcao_escolhida = null;
                  });
                });
                // Verifica se as questões já foram respondidas
                //this.verificaAvaliacoesPendentes(acao);
                this.hideModal('modalConfirmarRefazerAvaliacao')
                this.showModal("modalAvaliacao");
                if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00") {
                  this.verificaTempo();
                } 
                break;
              default:
                break;
            }
          } else {
            this.exibeToasty("Nenhuma questão encontrada", "error")
          }
        });
    },
    async getResultadoAvaliacao() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/questoes/lista?id_secretaria_avaliacao_aluno=" + this.fastAtividadeAtual.id_secretaria_avaliacao_aluno, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          let acertos = 0;
          let erros = 0;
          let nota = 0;
          let peso_total = 0;
          obj.forEach((q) => {
            peso_total += q.questao_peso;
            if (q.acertou != "S") {
              erros++;
            } else {
              nota += q.questao_peso;
              acertos++;
            }
          });
          this.chartAvaliacaoVisivel = true;
          this.fastPesoAvaliacao = peso_total ? parseFloat(peso_total).toFixed(2) : 0

          if (this.fastAvaliacao.nota_final_avaliacao === null) {
            this.fastNotaAvaliacao = nota ? parseFloat(nota).toFixed(2) : 0
          } else {
            this.fastNotaAvaliacao = this.fastAvaliacao.nota_final_avaliacao ? parseFloat(this.fastAvaliacao.nota_final_avaliacao).toFixed(2) : 0
          }

          this.fastNotaAvaliacaoArredondada = 0

          if (this.fastAvaliacaoConfiguracaoArredondamentos.length) {
            this.fastAvaliacaoConfiguracaoArredondamentos.forEach(e => {
              if (this.fastNotaAvaliacao >= e.faixa_inicial && this.fastNotaAvaliacao <= e.faixa_final) this.fastNotaAvaliacaoArredondada = e.nota
            })
          }

          if (this.fastNotaAvaliacaoArredondada) {
            if (this.fastNotaAvaliacaoArredondada >= this.fastCursoPlataforma.nota_media) this.fastUsuarioResultado.aprovado = true
            else
              this.fastUsuarioResultado.aprovado = false
          } else {
            if (this.fastNotaAvaliacao >= this.fastCursoPlataforma.nota_media) this.fastUsuarioResultado.aprovado = true
            else
              this.fastUsuarioResultado.aprovado = false
          }

          this.showModal('modalResultadoAviso')

          const notaNaoAtingida = 10.00 - parseFloat(this.fastNotaAvaliacao)          

          this.chartDataProva = [acertos, erros];
          this.chartDataProvaPeso = [parseFloat(this.fastNotaAvaliacao), parseFloat(notaNaoAtingida)]

          this.fastLoadingAvalicao = false
        }
      } catch (e) {
        this.exibeToasty(e, "error");
        console.log(e)
        this.fastLoadingAvalicao = false
      }
    },
    async getPesoAvaliacao() {
      let prova_peso = 0;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/questoes/lista_peso?id_secretaria_avaliacao=" + this.$route.params.id_secretaria_avaliacao, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          prova_peso = obj[0].prova_peso;
        }
        this.fastAtividadeAtual.prova_peso = prova_peso;
      } catch (e) {
        this.exibeToasty(e, "error");
        console.log(e)
      }
    },
    async verificaTempo() {
      
      // if (!this.fastAtividadeAtual.avaliacao_finalizada && this.fastAtividadeAtual.data_previsto_fim) {
        this.fastAvaliacaoTempoIniciada = true;
        let dataTermino = new Date(this.fastAtividadeAtual.data_previsto_fim);
        console.log('datatermino',dataTermino);
        dataTermino.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
        // Pegar hora no servidor
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/lista_hora", this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = JSON.stringify(json);
          let dataAtual = new Date(obj.split(":")[1].slice(1,15) + ":" + obj.split(":")[2] + ":" + obj.split(":")[3].slice(0,2));
          console.log('dataatual', dataAtual);

          if (dataAtual > dataTermino) {
            this.exibeToasty("Tempo esgotado", "error");
            this.exibeToasty("Tempo esgotado", "error");
            this.exibeToasty("Tempo esgotado", "error");

            this.tempoEsgotado();
          } else if ((dataTermino - dataAtual) > 120000) {
            setTimeout(() => {
              console.log('Check time');
              
              this.verificaTempo();
            }, 60000);
          } else {
            setTimeout(() => {
              console.log('Check time');
              
              this.verificaTempo();
            }, 10000);
          }
        } catch (e) {
          this.exibeToasty(e, "error");
          console.log(e)
        }
      // }
    },
    async tempoEsgotado() {
      console.log("tempoEsgotado")
      this.fastAtividadeAtual.avaliacao_finalizada = true;
      let obj = {
        tipo: this.getUrlParameter("tipo"),
        fast_secretaria_avaliacao_aluno: {
          id_secretaria_avaliacao: this.$route.params.id_secretaria_avaliacao,
          id_secretaria_curso_programa_unidade: this.fastAvaliacao.id_secretaria_curso_programa_unidade,
          id_plataforma: this.$route.params.id_plataforma,
          id_secretaria_avaliacao_aluno: this.fastAtividadeAtual.id_secretaria_avaliacao_aluno
        },
        titulo_avaliacao: this.fastAvaliacao.titulo_avaliacao,
        id_programa_turma: this.$route.params.id_programa_turma,
        id_curso: this.fastAvaliacao.id_curso
      };
      // let obj = {
      //   fast_secretaria_avaliacao_aluno: {
      //     id_secretaria_avaliacao_aluno: this.fastAtividadeAtual.id_secretaria_avaliacao_aluno,
      //   },
      //   id_plataforma: this.$route.params.id_plataforma,
      // };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/finaliza", this.fastAjaxOptions("POST", JSON.stringify(obj)));
        let json = await resp.json();
        let res = Array.from(json);
          if (res.length) {
            console.log(res);
            this.fastAtividadeAtual.count = res[0].count;
          }
        // Seta uma data para identificar que finalizou
        this.fastAtividadeAtual.data_fim = new Date();
        this.getResultadoAvaliacao();
        this.verificaAvaliacoesPendentes("finalizou");
        this.hideModal("modalAvaliacao");
      } catch (e) {
        this.exibeToasty(e, "error");
        console.log(e)
      }
    },
    async finalizaAvaliacaoTentativa() {
      if ((this.fastAtividadeAtual.count < this.fastAtividadeAtual.tentativas_maxima || !this.fastAtividadeAtual.tentativas_maxima || this.fastAtividadeAtual.tentativas_maxima == "0") && !this.fastLoadingAvalicao) {
        this.fastLoadingAvalicao = true;

        let id_curso = this.fastAvaliacao.id_curso
        //if (this.getUrlParameter("tipo") == 'R') id_curso = this.fastAvaliacao.id_curso_uc_recuperacao

        let titulo_avaliacao = this.fastAvaliacao.titulo_avaliacao
        if (this.getUrlParameter("tipo") == 'R') titulo_avaliacao = this.fastAvaliacao.titulo_recuperacao

        let obj = {
          tipo: this.getUrlParameter("tipo"),
          fast_secretaria_avaliacao_aluno: {
            id_secretaria_avaliacao: this.$route.params.id_secretaria_avaliacao,
            id_secretaria_curso_programa_unidade: this.fastAvaliacao.id_secretaria_curso_programa_unidade,
            id_plataforma: this.$route.params.id_plataforma,
            id_secretaria_avaliacao_aluno: this.fastAtividadeAtual.id_secretaria_avaliacao_aluno
          },
          titulo_avaliacao: titulo_avaliacao,
          id_programa_turma: this.$route.params.id_programa_turma,
          id_curso: id_curso
        };
        /*
                let obj = {
                  fast_secretaria_avaliacao_aluno: {
                    id_secretaria_avaliacao_aluno: this.fastAtividadeAtual.id_secretaria_avaliacao_aluno,
                  },
                  id_plataforma: this.$route.params.id_plataforma,
                };*/
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_aluno/finaliza", this.fastAjaxOptions("POST", JSON.stringify(obj)));
          let json = await resp.json();
          let res = Array.from(json);
          if (res.length) {
            console.log(res);
            
            // Seta uma data para identificar que finalizou
            this.fastAtividadeAtual.data_fim = res[0].data_fim;
            this.fastAtividadeAtual.tentativas_maxima = res[0].tentativas_maxima;
            this.fastAtividadeAtual.count = res[0].count;
          }

          this.verificaAvaliacoesPendentes("finalizou");
        } catch (e) {
          this.exibeToasty(e, "error");
          console.log(e)
          this.fastLoadingAvalicao = false;
        }
      }
    },
    provaDownloadGabarito() {

      let erros = []
      if (!this.fastAtividadeAtual.id_secretaria_avaliacao_aluno) erros.push("Identificador da avaliação não identificado")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.exibeToasty("Buscando gabarito", "info")
        this.promiseGetAvaliacaoQuestoes()
          .then((obj) => {
            if (obj.length) {
              // Separa as questões e alternativas
              const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
                return obj.find((a) => a.id_questao === id_questao);
              });
              questoesUnicas.forEach((a, index) => {
                obj.forEach((q, index2) => {
                  if (a.id_questao == q.id_questao) {
                    if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                    questoesUnicas[index].alternativas.push(q);
                  }
                });
              });
              // passando dentro de questoesUnicas para tirar o "&nbsp" de pergunta e alternativa
              questoesUnicas.forEach(a => {
                // Atualização (replaceAll é incompatível em sistemas mais antigos)
                a.pergunta = a.pergunta.replace(/&nbsp;/g, ' ');
                a.alternativas.forEach(q => {
                  q.alternativa = q.alternativa.replace(/&nbsp;/g, ' ')
                })
              })
              this.fastAtividadeAtual.avaliacao = questoesUnicas
              this.$toast.clear()

              let gabarito = ""
              this.fastAtividadeAtual.avaliacao.forEach(q => {
                gabarito += `<tr class="text-center">
                  <td>#${q.id_questao}</td>
                  <td>#${q.opcao_escolhida}</td>
                  </tr>`
              })

              this.htmlProvaGabarito = `
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <h1 class="text-info">${this.nome_plataforma}</h1>
                    </div>
                    <div class="col-6 text-right">                      
                      <h5 class="text-info">Data da realização: ${this.formataDataT(this.fastAtividadeAtual.data_fim)}</h5>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <h4>Gabarito - ${this.fastAvaliacao.nome_curso ? this.fastAvaliacao.nome_curso : this.fastAvaliacao.nome_curso_mae}</h4>
                    </div>
                    <div class="col-12 table-responsive">
                      <table class="table table-sm table-bordered">
                        <tr class="text-center">
                          <th class="pt-0 pb-0">Questão</th>
                          <th class="pt-0 pb-0">Alternativa</th>
                        </tr>
                        ${gabarito}              
                      </table>
                    </div>
                  </div>
                  <div class="row mt-4 text-center">
                      <div class="col-12">
                        _____________________________________________________
                      </div>
                      <div class="col-12">
                        <h5>Assinatura do aluno</h5>
                      </div>
                    </div>
                </div>
              `;
              this.$refs.html2Pdf.generatePdf();
              /*
                            this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/logo_plataforma_tobase64",`url=${this.logo_plataforma}`)
                              .then((res) => {
                                console.log("logo_plataforma_tobase64", res)
                                  this.htmlProvaGabarito = `
                                  <div class="container">
                                    <div class="row">
                                      <div class="col-6">
                                        <img src="${res}" style="max-width: 200px">
                                      </div>
                                      <div class="col-6 text-right">
                                        <h5 class="text-info">Gabarito da prova ${this.fastAtividadeAtual.id_usuario_atividade}</h5>
                                      </div>
                                    </div>
                                  </div>
                                `;
                                this.$refs.html2Pdf.generatePdf();
                              })
                              .catch((e) => {
                                this.htmlProvaGabarito = `
                                  <div class="container">
                                    <div class="row">
                                      <div class="col-6">
                                        <img src="" style="max-width: 200px">
                                      </div>
                                      <div class="col-6 text-right">
                                        <h5 class="text-info">Gabarito da prova ${this.fastAtividadeAtual.id_usuario_atividade}</h5>
                                      </div>
                                    </div>
                                  </div>
                                `;
                                this.$refs.html2Pdf.generatePdf();
                              });
                              */


            } else {
              this.exibeToasty("Nenhuma questão encontrada", "error")
            }
          })
          .catch(e => {this.exibeToasty(e, "error") 
          console.log(e)})
      }
    },
    fileSuccessGabarito(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.exibeToasty("Gabarito enviado com sucesso", "success")
        this.fastPathGabarito = result.url
      } else {
        this.exibeToasty("Erro ao fazer upload", "error")
      }
    },
    retornaLetraAlternativa(index) {
      const letra = ["a", "b", "c", "d", "e", "f"];
      return letra[index];
    },
    fileValidation(e) {
      return false;
    },
    // Porcentagem
    async getFastUcPorcentagem() {
      let id_curso = this.fastAvaliacao.id_curso_uc_recuperacao
      if (this.getUrlParameter("tipo") == "A" || !id_curso) id_curso = this.fastAvaliacao.id_curso

      try {
        let resp = await fetch(
          settings.endApiFastEad +
          "api/fast_usuario_curso/verifica_certificado?id_curso=" +
          id_curso +
          "&id_plataforma=" +
          this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json()
        let obj = Array.from(json);
        if (obj.length) {
          this.fastRequisitosAvaliacao.uc_porcentagem = obj[0].status.split(" ").pop()
          if (this.fastRequisitosAvaliacao.uc_porcentagem >= 100 && this.fastAvaliacaoConfiguracao.final_modulo) {

            this.getUrlParameter("tipo") == "A" ? this.fastRequisitosAvaliacao.disponivel = true : this.fastRequisitosRecuperacao.disponivel = true

          }
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scope>
.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-enter

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.ql-container {
  height: auto !important;
}

.fast-aula-alternativa:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.fast-aula-alternativa-escolhida {
  background-color: rgb(192, 192, 192);
}

.fast-aula-alternativa-escolhida-correta {
  background-color: rgb(82, 255, 97);
}

.fast-aula-alternativa-escolhida-incorreta {
  background-color: rgb(255, 58, 58);
}

.VueCarousel-slide {
  height: 100%;
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}

/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}

/* Personalizado */
@media (max-width: 768px) {
  .con-classroom {
    padding: 0;
  }

  #sala {
    padding: 0 !important;
  }

  .fast-aula-conteudo-principal {
    box-shadow: 0 0 !important;
    padding: 10px !important;
  }

  .con-classroom-aula-options {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
  }

  .con-classroom-aula-options .con-classroom-options-next,
  .con-classroom-aula-options .con-classroom-options-prev {
    margin-top: 0;
  }

  .con-timeline {
    margin-bottom: 0;
  }

  .VueCarousel-wrapper img {
    max-width: 100%;
  }
}

/* Bug no carousel no firefox */
@-moz-document url-prefix() {
  .VueCarousel-slide-adjustableHeight {
    display: block !important;
  }
}</style>
